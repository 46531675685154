import * as React from 'react'
import { useEffect } from "react"
import * as Styles from './reviewsSignpost.module.scss'
import {Helmet} from "react-helmet";
import c from 'classnames'

const Layout = ({ section, images, breeds }) => {
	let pageData = section.attributes.data;
	let title = pageData.rs_title;
	let content = pageData.rs_content;
	let dataArray = [];
	for (var i=0; i < pageData.rs_reviews_manual; i++) {
		let date = 'rs_reviews_manual_' + i + '_date';
		let logo = 'rs_reviews_manual_' + i + '_logo';
		let name = 'rs_reviews_manual_' + i + '_name';
		let stars = 'https://dev-petted2.pantheonsite.io/wp-content/uploads/2022/01/stars.svg';
		let text = 'rs_reviews_manual_' + i + '_text';
		let title = 'rs_reviews_manual_' + i + '_title';
		dataArray.push({
			name: pageData[name],
			date: pageData[date],
			stars: stars,
			text: pageData[text],
			title: pageData[title],
			image: getImageUrl(pageData[logo]),
		});
	}

	function getImageUrl(id) {
		for (var i = 0; i < images.length; i++){
			if (images[i].node.databaseId === id){
			   return images[i].node.sourceUrl;
			}
		}
	}

	useEffect(() => {
		import('tiny-slider').then(({ tns }) => {
			setTimeout(() => {
				if (typeof window !== 'undefined') {
					var slider = tns({
						container: '#slider',
						items: 1,
						slideBy: 'page',
						touch: false,
						mouseDrag: false,
						nav: true,
						controls: false,
						loop: false,
						gutter: 10,
						autoplay: true,
					});
				}
			}, 1);
		})
	}, [])

	function filterDataByInsurer(data) {
		if(data != '') {
			if (typeof window !== 'undefined') {
				document.location.href = data;
			}
		}
	}

	setTimeout(() => {
		if (typeof window !== 'undefined') {
			let Choices = require('choices.js');
			let sortSelect = document.getElementById('pet_type');
			let starsSelect = document.getElementById('breed');

			const sortChoices = new Choices(sortSelect, {
				allowHTML: false
			});
			const starsChoices = new Choices(starsSelect, {
				allowHTML: false
			});
		}
	}, 1000);

	return (
		<div className="section">
			<Helmet>
				<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/choices.js/public/assets/styles/choices.min.css" />
			</Helmet>
			<div className="container items-center"> 
				<div className="col col-md-1-2 center-small">
				{
						(title) ?
							<h2 className="textBlockTitle" dangerouslySetInnerHTML={{__html: title}}></h2>
						: ''
					}
					{
						(content) ? 
							<div className="textBlockInner">
								<p dangerouslySetInnerHTML={{__html: content}}></p>
							</div>
						: ''
					}

					<form className="container" id="filter-breed" action="/pet-insurance-reviews/all-reviews"> 
						<div className="flex">
							<div>
								<label>Pet type</label>
								<select 
									data-trigger
									id='pet_type'
									name="pet_type"
									className="form-control"
									disabled>
									<option value="dog" className="js-choice" selected>Dog</option>
									<option value="cat" className="js-choice" disabled>Cat</option>  
								</select> 
							</div>
							<div>
								<label for="filter_by_term">Breed type</label>
								<select 
									data-trigger
									name="filter_by_term"
									id="breed" 
									className="form-control"
									onChange={e => filterDataByInsurer(e.target.value, true)}>
									<option value="">All breeds</option>
									{
										(breeds).map((breed, index) => {
											return (
												<option value={'/pet-insurance-reviews/all-reviews?breed=' + encodeURIComponent(breed.node.name)}>{breed.node.name}</option>
											)
										})
									}
								</select> 
							</div>
						</div>
						<input type="submit" value="Find matching reviews" className="btn" />
					</form>
		
					{/* <?php endif; ?>
					
					<?php echo ($button_link) ? '<a href="'.$button_link['url'].'" className="btn mt btn--slim">'.$button_text.'</a>' : ''; ?> */}
				</div>
				<div className="col col-md-1-2">
					<div className={c('bg-alt-radius', Styles.reviewContainer, 'reviewSignpostWrap')}>
						<div id="slider" className={c('review-slider', 'slider-wrap', Styles.SlideWrap)}>
							{
								(dataArray).map((data, index) => {
									return (
										<div className={c(Styles.slide, 'slide')}>
											<div className={Styles.slideInner}>
												<div className={Styles.slideLogo}>
													<img loading='lazy' src={data.image} alt="Review Logo" />
												</div>
												<div className={Styles.slideStars}>
													<img loading='lazy' src={data.stars} alt="5 Stars" />
												</div>
												<div className={Styles.slideTitle}>
													<h4>{data.title}</h4>
												</div>
												<div className={Styles.slideText}>
													<p>{data.text}</p>
												</div>
												<div className={Styles.slidePersonDate}>
													<div className={Styles.slidePerson}>
														<p>{data.name}</p>
													</div>
													<div className={Styles.slideDate}>
														<p>{data.date}</p>
													</div>
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Layout