// extracted by mini-css-extract-plugin
export var reviewContainer = "reviewsSignpost-module--reviewContainer--6a9d7";
export var slide = "reviewsSignpost-module--slide--8b00d";
export var slideDate = "reviewsSignpost-module--slideDate--f2308";
export var slideLogo = "reviewsSignpost-module--slideLogo--efcca";
export var slidePerson = "reviewsSignpost-module--slidePerson--e8451";
export var slidePersonDate = "reviewsSignpost-module--slidePersonDate--4e14b";
export var slideStars = "reviewsSignpost-module--slideStars--07143";
export var slideText = "reviewsSignpost-module--slideText--65c8b";
export var slideTitle = "reviewsSignpost-module--slideTitle--cb2bb";
export var slideWrap = "reviewsSignpost-module--slideWrap--14abe";